@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {

  margin: auto;
  width: 107%;
  text-align: center;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
  
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 250px;

  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}


/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius:20px 20px;
  
}

/* Style the front side (fallback if image is missing) */

.cardimg
{
  border-radius: 100%;
  width: 100%;
}

/* Style the back side */
.flip-card-back {
background-color: 'black';
  transform: rotateY(180deg);
}